import React, { Component } from 'react';
import clsx from 'clsx';
import FlipMove from 'react-flip-move';

class Lobby extends Component {
  render() {
    const sortedPlayerIds = Object.keys(this.props.players).sort((a, b) => {
      const aActive = this.props.players[a].isActive;
      const bActive = this.props.players[b].isActive;
      if(aActive !== bActive) {
        return aActive ? -1 : 1;
      }

      const scoreDiff = this.props.players[a].bufferedScore - this.props.players[b].bufferedScore;
      if (scoreDiff === 0) {
        return this.props.players[a].name.localeCompare(this.props.players[b].name);
      }
      return scoreDiff;
    });

    return (
      <div className="Lobby">
        <div className="players">
          <FlipMove>
          {sortedPlayerIds.map(p => {
            const player = this.props.players[p];

            return (
              <div className={clsx('player', {
                '-current': player.isCurrent,
                '-gm': player.isGm,
                '-inactive': !player.isActive,
              })} key={player.id}>
                {this.props.isGm &&
                  <div className="gm-actions">
                    {this.props.showGmInterface && !player.isGm &&
                      <>
                      <button className="kick" onClick={() => this.props.kickPlayer(player.id)}>x</button>
                      <button className="make-gm" onClick={() => this.props.makeGm(player.id)}>{'👑'}</button>
                      </>
                    }
                    <button className="toggle-active" onClick={() => this.props.toggleActive(player.id)}>🗑️</button>
                  </div>
                }
                <span className="name">{player.name}</span>
                {this.props.isGm &&
                  <button className="decrease" onClick={() => this.props.incrementPoints(player.id, -1)}>-</button>
                }
                <span className="score">{player.score}</span>
                {this.props.isGm &&
                  <button className="increase" onClick={() => this.props.incrementPoints(player.id, 1)}>+</button>
                }
              </div>
            );
          })}
          </FlipMove>
        </div>
        <div className="actions">
          <div>
            <button onClick={this.props.leave} className="btn-alt leave">Leave</button>
            <button onClick={this.props.share} className="btn-alt share">Invite friends</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Lobby;