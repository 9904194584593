import React, { Component } from 'react';

import 'normalize.css';
import './App.scss';

import { initializeApp } from 'firebase/app';
import { getFirestore, doc, updateDoc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';

import Game from './components/Game';
import MainMenu from './components/MainMenu';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BeatLoader from "react-spinners/BeatLoader";

var config = {
  apiKey: "AIzaSyDUf8COAVRoB2CzpnOd-5JDVNwZt3GJnTU",
  authDomain: "the-golden-mile-e403f.firebaseapp.com",
  projectId: "the-golden-mile-e403f",
  storageBucket: "the-golden-mile-e403f.appspot.com",
  messagingSenderId: "576615891248",
  appId: "1:576615891248:web:e4c3258a5bab015b8488f5"
};
initializeApp(config);

// Initialize Cloud Firestore through Firebase
var db = getFirestore();
const auth = getAuth();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      userId: "",
      game: "",
      lockGameId: false,
    }
  }

  componentDidMount(){
    const params = new URLSearchParams(window.location.search);
    if(params.has('game')){
      this.setGame(params.get('game'));
      this.setState({
        lockGameId: true,
      });
    }

    onAuthStateChanged(auth, async user => {
      if(user){
        await this.setUserId(user.uid);
      }else{
        try{
          await signInAnonymously(auth);
        }catch(e){
          alert('Error logging in');
        }
      }
    });
  }

  setGame(value) {
    const params = new URLSearchParams(window.location.search);
    params.set('game', value);
    window.history.replaceState(false, false, '?' + params.toString());

    this.setState({
      game: value,
    });

    if(!value){
      this.setState({
        lockGameId: false,
      });
    }
  }
  setName(value) {
    updateDoc(doc(db, `users/${ this.state.userId }`), {
      name: value,
    });
  }
  async setUserId(userId){
    const docRef = doc(db, `users/${ userId }`);
    const user = await getDoc(docRef);

    if(!user.exists()){
      console.log('New user created', userId);

      // Register new user
      await setDoc(docRef, {
        name: "",
      });
    }

    this.setState({
      userId,
    });

    onSnapshot(docRef, snapshot => {
      this.setState({
        name: snapshot.data().name,
      });
    });
  }

  toast = (text) => toast(text, {
      type: toast.TYPE.INFO,
      hideProgressBar: true,
    }); 

  render() {
    return (
      <div className="App">
        <div className="info header">
          <img alt="The Golden Mile" src="logo.svg" width="200" height="50"/>
        </div>
        {this.state.game && this.state.name && this.state.userId
          ? <Game db={db} id={this.state.game} player={this.state.userId} name={this.state.name} leave={() => this.setGame('')} toast={this.toast} />
          : (this.state.userId
              ? <MainMenu game={this.state.game} name={this.state.name} lockGameId={this.state.lockGameId} setGame={this.setGame.bind(this)} setName={this.setName.bind(this)} />
              : <div className="loading"><BeatLoader speedMultiplier="1" color="#c69c0d"/></div>
          )
        }
        <ToastContainer />
      </div>
    );
  }
}

export default App;
