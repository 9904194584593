import React, { Component } from 'react';
import { doc, deleteField, getDoc, setDoc, updateDoc, onSnapshot, increment } from 'firebase/firestore';
import _ from 'lodash';

import Lobby from './Lobby';
// import TopicSelection from './TopicSelection';
// import StudyPhase from './StudyPhase';
// import QuestionPhase from './QuestionPhase';
// import EndScreen from './EndScreen';

import copy from 'copy-to-clipboard';


const PHASE = {
    LOBBY: 0,
    // TOPIC_SELECTION: 1,
    // STUDY_PHASE: 2,
    // QUESTION_PHASE: 3,
    // END_SCREEN: 4,
}

class Game extends Component {
    audio1 = new Audio('horn0.mp3');
    audio2 = new Audio('horn4.mp3');
    audio3 = new Audio('horn7.mp3');
    setBufferedScoresTimeout = false;

    constructor(props) {
        super(props);
        this.state = {
            showGmInterface: false,
            players: {},
            unsubscribe: () => {},
        };        
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id) {
            this.load();
        }
        if (prevState.hasOwnProperty('phase') && this.state.phase !== prevState.phase) {
            this.handleNewPhase();
        }


        this.join();
    }

    async load() {
        this.state.unsubscribe();

        await this.create();
        
        const unsubscribe = onSnapshot(this.docRef(), snapshot => {
            const data = snapshot.data();
            if(data.celebrate > this.state.celebrate){
                this.celebrate();
            }

            // Delay reordering until scores have settled
            if(!_.isEqual(this.state.scores, data.scores)){
                if(this.setBufferedScoresTimeout){
                    clearTimeout(this.setBufferedScoresTimeout);
                }
    
                this.setBufferedScoresTimeout = setTimeout(() => {
                    this.setState((state) => ({
                        bufferedScores: state.scores,
                    }));
                }, 500);
            }

            this.setState({
                ...data,
                unsubscribe,
            });
        });
    }

    join() {
        updateDoc(this.docRef(), {
            [`players.${ this.props.player }`]: this.props.name,
        });
    }

    async create() {
        const doc = await getDoc(this.docRef());
        if(!doc.exists()){
            await setDoc(this.docRef(), {
                gm: this.props.player,
                phase: PHASE.LOBBY,
                players: {},
            });
        }
    }

    async reset() {
        if(!this.isGm) return;

        await updateDoc(this.docRef(), {
            answer: '',
            guess: '',
            topic: 0,
            phase: PHASE.LOBBY,
            players: _.mapValues(this.state.players, () => ''),
        });
    }

    docRef() {
        return doc(this.props.db, `games/${ this.props.id.toLowerCase() }`);
    }

    kickPlayer(name) {
        updateDoc(this.docRef(), {
            [`players.${ name }`]: deleteField(),
        });
    }

    incrementPoints(name, value) {
        updateDoc(this.docRef(), {
            [`scores.${ name }`]: increment(value),
        });
    }

    toggleActive(name){

        updateDoc(this.docRef(), {
            [`inactive.${ name }`]: increment(1),
        });
    }

    celebrate(){
        try{
            this.audio1.volume = 1;
            this.audio2.volume = 1;
            this.audio3.volume = 1;
        }catch(e){}

        try{
            this.audio1.play();
            this.audio2.play();
            this.audio3.play();
        }catch(e){}
    }

    nextPhase() {
        if(!this.isGm) return;

        updateDoc(this.docRef(), {
            phase: (this.state.phase + 1) % _.size(PHASE),
        });
    }

    handleNewPhase() {
        switch(this.state.phase){
            case PHASE.LOBBY:
                this.reset();
                break;
            default:
                break;
        }
    }

    isGm() {
        return this.props.player === this.state.gm;
    }

    toggleGmInterface() {
        this.setState({
            showGmInterface: !this.state.showGmInterface,
        });
    }

    makeGm(newGm){
        if(!this.isGm()) return;

        updateDoc(this.docRef(), {
            gm: newGm,
        });
    }

    awardScore(){
        if(!this.isGm) return;

        const scores = _.mapValues({[this.state.gm]: '', ...this.state.players}, (_unused, player) => {
            let score = _.get(this.state, ['scores', player], 0);
            if(player === this.state.gm && this.isCorrectGuess()){
                score++;
            }else if(player === this.state.guess){
                score++;
            }
            return score;
        });
        updateDoc(this.docRef(), {
            scores,
        });
    }

    getShareLink(){
        return `${ window.location.origin }${ window.location.pathname }?game=${ this.props.id }`
    }
    share() {
        if(navigator.share){
            navigator.share({
                url: this.getShareLink(),
            })
              .catch(() => this.shareFallback());
        }else{
            this.shareFallback();
        }
    }
    shareFallback(){
        if(copy(this.getShareLink())){
            this.props.toast('Copied link!');
        }
    }

    triggerCelebrate(){
        updateDoc(this.docRef(), {
            [`celebrate`]: increment(1),
        });
    }
  
    render() {

        const players = _.mapValues(this.state.players || {}, (name, id) => ({
            id,
            name,
            score: _.get(this.state.scores, [id], 0),
            bufferedScore: _.get(this.state.bufferedScores, [id], _.get(this.state.scores, [id], 0)),
            isActive: !(_.get(this.state.inactive, [id], 0) % 2),
            isGm: this.state.gm === id,
            isCurrent: this.props.player === id,
        }));

        return (
            <div className="Game">
                {{
                    [PHASE.LOBBY]: <Lobby
                        isGm={this.isGm()}
                        id={this.props.id}
                        players={players}
                        gm={this.state.gm}
                        showGmInterface={this.state.showGmInterface && this.isGm()}
                        kickPlayer={this.kickPlayer.bind(this)}
                        toggleActive={this.toggleActive.bind(this)}
                        makeGm={this.makeGm.bind(this)}
                        startGame={this.nextPhase.bind(this)}
                        leave={this.props.leave}
                        share={this.share.bind(this)}
                        incrementPoints={this.incrementPoints.bind(this)}
                    />,
                }[this.state.phase]}
                {/*this.isGm() && <button onClick={this.reset.bind(this)} className="reset-game btn-alt">Restart</button>*/}
                {this.isGm() && <button onClick={this.toggleGmInterface.bind(this)} className="toggle-gm-interface btn-alt">👑</button>}
                {this.isGm() && <button onClick={this.triggerCelebrate.bind(this)} className="celebrate btn-alt">🎉</button>}
            </div>
        );
    }
}

export default Game;